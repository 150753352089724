import React from "react";
import BeeSpline from "./BeeSpline";

const FooterSection = () => {
  return (
    <footer className="py-8 sm:py-10 md:py-16 bg-black text-white">
      <div className="flex justify-center items-center text-center px-4 sm:px-8">
        <h1 className="font-[Montserrat] font-extrabold text-2xl sm:text-3xl md:text-5xl text-customOrange">
          Making every buzz count
        </h1>
      </div>

      <div className="my-6 sm:my-8 md:my-12">
        <BeeSpline />
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center w-full px-4 sm:px-8 md:px-16">
        <div className="mb-4 md:mb-0 text-center md:text-left">
          <p className="text-sm sm:text-base md:text-lg">
            &copy; 2024 Copyright,
            <br /> Beebox Studios Private Limited.
          </p>
        </div>

        <div className="mb-4 md:mb-0 text-center md:text-left">
          <p className="text-sm sm:text-base md:text-lg flex items-center gap-1">
            Made with <span className="text-yellow-400">💛</span> from Chennai
            <span role="img" aria-label="India Flag">
               🇮🇳
            </span>
          </p>
        </div>

        <div className="text-center md:text-right">
          <a
            href="https://www.beeboxstudios.com/privacy-policy"
            className="text-sm sm:text-base md:text-lg underline hover:text-sky-900"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
