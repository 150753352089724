import React from 'react';
import Spline from '@splinetool/react-spline';

const BeeSpline = () => (
  <div className="flex justify-center   p-6 2xl:p-10 md:p-8">
    <div className="flex  2xl:w-full w-96  h-80  md:max-w-2xl 2xl:max-w-4xl">
      <Spline scene="https://prod.spline.design/cYtblTmTnk2R9qLl/scene.splinecode" />
    </div>
  </div>
);

export default BeeSpline;
